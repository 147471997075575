<template>
  <section id="member_signup">
    <div class="main">
      <div class="signup_box">
        <div class="main_title txt-center">
          <h3 class="eng">CREATE ACCOUNT</h3>
          <h5>
            {{ $t("signup") }}
            <img class="deco_flower" src="@/assets/img/deco_flower.svg" alt="" />
          </h5>
        </div>
        <form class="form" id="regist_form" name="regist_form">
          <div class="item50">
            <p>{{ $t("name") }}<span class="txt-main">*</span></p>
            <input
              type="text"
              name="cname"
              id="cname"
              maxlength="20"
              :placeholder="this.$t('namePlaceHolder')"
              v-model="formData.name"
            />
          </div>
          <div class="item25">
            <p>{{ $t("date") }}<span class="txt-main">*</span></p>
            <date-pick
              :selectableYearRange="{ from: 1940, to: new Date().getUTCFullYear() }"
              :inputAttributes="{
                id: 'birthday',
                name: 'birthday',
                placeholder: 'ex.1990/01/01',
                readonly: true
              }"
              v-model="formData.birthday"
            >
            </date-pick>
          </div>
          <div class="item25">
            <p>{{ $t("gender") }}<span class="txt-main">*</span></p>
            <input type="radio" name="sex" id="sex_male" value="f" v-model="formData.sex" />
            <label for="sex_male"><span></span> {{ $t("female") }}</label>
            <input type="radio" name="sex" id="sex_female" value="m" v-model="formData.sex" />
            <label for="sex_female"><span></span> {{ $t("male") }}</label>
          </div>
          <div class="item50">
            <p>{{ $t("accout") }}<span class="txt-main">*</span></p>
            <input
              type="text"
              name="acc"
              maxlength="10"
              id="acc"
              :placeholder="this.$t('pidOrPassportNum')"
              v-model="formData.account"
            />
          </div>
          <div class="item50">
            <p>{{ $t("contactNumber") }}<span class="txt-main">*</span></p>
            <div class="item_inner col100">
              <div class="col33">
                <select name="country" id="country" v-model="formData.country_code">
                  <option value="">
                    ---
                  </option>
                  <option v-for="(item, key) in countryCode" :value="item" :key="key">
                    {{ item }}
                  </option>
                </select>
              </div>
              <div class="col66">
                <input
                  type="text"
                  name="mobile"
                  id="mobile"
                  maxlength="10"
                  :placeholder="this.$t('contactPlaceholder')"
                  v-model="formData.phone"
                />
              </div>
            </div>
          </div>
          <div class="item50">
            <p>{{ $t("password") }}<span class="txt-main">*</span></p>
            <div class="password_outer">
              <input
                :type="ifPasswordAbleToSee ? 'text' : 'password'"
                id="upwd"
                name="upwd"
                maxlength="20"
                :placeholder="this.$t('passwordPlaceholder')"
                v-model="password"
              />
              <img
                src="@/assets/img/eye.jpg"
                alt=""
                class="eye"
                @click="ifPasswordAbleToSee = !ifPasswordAbleToSee"
              />
            </div>
          </div>
          <div class="item50">
            <p>{{ $t("confirmPassword") }}<span class="txt-main">*</span></p>
            <div class="password_outer">
              <input
                :type="ifPasswordAbleToSee ? 'text' : 'password'"
                id="upwd2"
                name="upwd2"
                maxlength="20"
                :placeholder="this.$t('refillPasswordPlaceholder')"
                v-model="checkPassword"
              />
              <img
                src="@/assets/img/eye.jpg"
                alt=""
                class="eye"
                @click="ifPasswordAbleToSee = !ifPasswordAbleToSee"
              />
            </div>
          </div>
          <div class="item50">
            <p>E-mail<span class="txt-main">*</span></p>
            <input
              type="text"
              name="email"
              id="email"
              :placeholder="this.$t('emailPlaceholder')"
              v-model="formData.email"
            />
          </div>
          <div class="item50">
            <p>{{ $t("verification") }}<span class="txt-main">*</span></p>
            <input
              type="text"
              name="verification"
              id="verification"
              v-model="formData.verify_code"
              :placeholder="this.$t('verificationPlaceholder')"
            />
            <div class="verification_set">
              <button type="button" class="small" @click="clickToGetVerifyCode(2)">
                {{ $t("getVerificationByTextMail") }}
              </button>
              <button type="button" class="small" @click="clickToGetVerifyCode(1)">
                {{ $t("getVerificationByEMail") }}
              </button>
            </div>
          </div>
          <div class="remember item100">
            <input type="checkbox" name="agree" id="agree" value="Y" v-model="agreePrivicy" />
            <label for="agree" style="margin: 0;"
              ><span></span>
              <p style="display:inline; width:auto;">
                {{ $t("agree") }}
                <span id="open_privacy" class="txt-blue">{{ $t("privacy") }}</span>
              </p>
            </label>
          </div>
          <div class="item100">
            <button
              type="button"
              id="send_btn"
              name="send_btn"
              class="btn col100 p"
              @click="clickSignUp()"
            >
              {{ $t("signup") }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div
      class="sub"
      :style="{ backgroundImage: `url(${require('@/assets/img/member/bg_2.jpg')})` }"
    >
      <div class="sub_box">
        <div class="main_title txt-center txt-white">
          <h3 class="eng">WELCOME NUWA</h3>
          <h5>
            {{ $t("areadyHasAccout") }}
            <img class="deco_flower" src="@/assets/img/deco_flower_white.svg" alt="" />
          </h5>
        </div>
        <router-link to="/login" class="btn btn-white">
          {{ $t("login") }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/login.scss';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { mapMutations } from 'vuex';

import {
  signUp, changePassword, getVerifyCode, getCountryCode,
} from '@/lib/public';

export default {
  name: 'Signup',
  components: { DatePick },
  data() {
    return {
      ifPasswordAbleToSee: false,
      countryCode: [],
      password: '',
      checkPassword: '',
      agreePrivicy: false,
      formData: {
        account: '',
        name: '',
        birthday: '',
        sex: '',
        country_code: '',
        phone: '',
        email: '',
        verify_code: '',
        agree_privicy: 0,
        send_way: '1',
      },
    };
  },
  watch: {
    agreePrivicy() {
      if (this.agreePrivicy) {
        this.formData.agree_privicy = 1;
      } else {
        this.formData.agree_privicy = 0;
      }
    },
  },
  methods: {
    ...mapMutations(['logIn']),
    clickSignUp() {
      const submitData = this.formData;

      // 與掛號那邊一樣規則
      submitData.country_code = submitData.country_code.replace('+', '');

      const AZRegExp = /[A-Z]/;
      const passwordRegExp = /[0-9a-zA-Z]/;
      const passwordValidate = AZRegExp.test(this.password)
        && passwordRegExp.test(this.password)
        && this.password.length >= 8;

      if (submitData.name === '' || submitData.name === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputName') });
      } else if (submitData.birthday === '' || submitData.birthday === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputBirth') });
      } else if (submitData.sex === '' || submitData.sex === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputGender') });
      } else if (submitData.account === '' || submitData.account === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputAccount') });
      } else if (this.password === '' || this.password === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputPassword') });
      } else if (!passwordValidate) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordError') });
      } else if (this.checkPassword === '' || this.checkPassword === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALComfirmPassword') });
      } else if (this.password !== this.checkPassword) {
        this.$customSWAL({ icon: 'error', title: this.$t('passwordComfirmError') });
      } else if (submitData.phone === '' || submitData.phone === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALInputNumber') });
      } else if (Number.isNaN(Number(submitData.phone)) || submitData.phone.length < 10) {
        this.$customSWAL({ icon: 'error', title: this.$t('請輸入正確的連絡電話') });
      } else if (submitData.country_code === '' || submitData.country_code === null) {
        this.$customSWAL({ icon: 'error', title: 'customSWALInputLocal' });
      } else if (submitData.email === '' || submitData.email === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALMailError') });
      } else if (submitData.verify_code === '' || submitData.verify_code === null) {
        this.$customSWAL({ icon: 'error', title: this.$t('verificationPlaceholder') });
      } else if (submitData.agree_privicy === 0) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALAgreePrivacy') });
      } else {
        signUp(
          submitData,
          // 註冊成功
          (response) => {
            this.logIn(response.data);
          },
          // 註冊失敗
          (message) => {
            this.$customSWAL({ icon: 'error', title: message });
          },
        ).then(() => {
          changePassword(
            this.$cookies.get('api_token'),
            {
              old_password: submitData.birthday.replace(/-/g, ''),
              password: this.password,
            },
            () => {
              this.$customSWAL({
                title: this.$t('successfulRegistration'),
                thenFunc: () => {
                  this.$router.push('/Login');
                },
              });
            },
          );
        });
      }
    },
    clickToGetVerifyCode(sendWay) {
      this.formData.send_way = sendWay;
      if (sendWay === 1 && !this.formData.email) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALMailError') });
      } else if (sendWay === 2 && !this.formData.phone) {
        this.$customSWAL({ icon: 'error', title: this.$t('customSWALPhoneError') });
      } else {
        getVerifyCode({
          phone: this.formData.phone,
          email: this.formData.email,
          send_way: sendWay,
        }).then((result) => {
          if (result.status === 400) {
            this.$customSWAL({ icon: 'error', title: result.data.message });
          } else {
            this.$customSWAL({ title: this.$t('sendingVerification') });
          }
        });
        // getVerifyCode(
        //   {
        //     phone: this.formData.phone,
        //     email: this.formData.email,
        //     send_way: sendWay,
        //   },
        //   (result) => {
        //     if (result.status) {
        //       this.$customSWAL({ title: '驗證碼已送出' });
        //     } else {
        //       this.$customSWAL({ icon: 'error', title: result.message });
        //     }
        //   },
        // );
      }
    },
  },
  mounted() {
    getCountryCode().then((response) => {
      // console.log(response.data);
      this.countryCode = [];
      response.data.forEach((element) => {
        // console.log(element);
        this.countryCode.push(`+${element.code2}`);
      });
      const prepend = ['+886', '+86', '+852', '+81', '+1'];
      prepend.forEach((item) => {
        const index = this.countryCode.indexOf(item);
        if (index !== -1) {
          this.countryCode.splice(index, 1);
        }
      });
      this.countryCode = [...prepend, ...this.countryCode];
    });
  },
};
</script>
